@import '../../../../../../styles/common.scss';

.wrapper {
  padding: 2rem 2rem 0rem 2rem;
  max-height: 490px;
  width: 35rem;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  h1 {
    color: $themePurple;
    font-family: 'UrbanistSemiBold';
    font-size: 1rem;
    margin-bottom: 1.8rem !important;
  }

  .meshio__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem;
    border: 1px solid grey;
    border-radius: 22px;

    .button {
      background-color: grey;
      padding: 0.3rem;
      border-radius: 5px;
      color: white;
      cursor: pointer;
    }
  }

  div {
    margin: 2rem 0;
  }
}

.wrapper::-webkit-scrollbar {
  display: none;
}

.modal-input {
  width: 30rem;
}

.button-container {
  display: flex;
  justify-content: flex-end;
  padding-right: 2rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.dropDown__inputs__container {
  display: flex;
  justify-content: space-between;
  width: 100%;

  &__dropDown {
    border: #353f94;
    background-color: #353f94;
  }
}

.width-35 {
  width: 35rem;
}
