@import '../../../../../../styles/common.scss';

.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 3rem 2rem !important;
  background: white;
  box-shadow: 0px 20px 30px #6575ff12;
  border-radius: 1.5rem;
  @media only screen and (max-width: 1024px) {
    flex-direction: row;
    padding: 1.5rem 2rem !important;
  }
  @media only screen and (max-width: 950px) {
    flex-direction: column;
    padding: 1.5rem 2rem !important;
  }
  .top_section {
    display: flex;
    flex-direction: row;
    align-items: center;

    .text_container {
      margin-left: 1rem;
      height: 6rem;
      padding: 0.5rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      h2 {
        font-size: 1.3rem;
        font-family: 'UrbanistSemiBold';
        color: $spintlyBlack;
      }
      p {
        font-size: 0.9rem;
        font-family: 'UrbanistSemiBold';
        color: $themePurple;
      }
      span {
        font-size: 0.9rem;
        font-family: 'UrbanistMedium';
        color: $cardBorder;
      }
    }
    
  }

  .border {
    height: 1px;
    background-color: $borderColor;
    width: 100%;
    margin: 3rem 0rem;
    @media only screen and (max-width: 1024px) {
      width: 1px;
      height: 250px;
      margin: 0rem 3rem;
    }
    @media only screen and (max-width: 1024px) {
      height: 1px;
      width: 100%;
      margin: 3rem 0rem;
    }
  }

  .bottom_section {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    .column {
      margin: 1rem 0rem;

      .label {
        color: $themePurple;
        font-size: 0.9rem;
        font-family: 'UrbanistSemiBold';
      }
      .text {
        color: $spintlyBlack;
        font-size: 0.95rem;
        font-family: 'UrbanistSemiBold';
      }
    }

    .button_container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-evenly;
      margin-top: 2rem;
      @media only screen and (max-width: 1024px) {
        width: 400px;
      }
      @media only screen and (max-width: 950px) {
        width: 80%;
      }
      button {
        margin: 0 0.2rem;
      }
    }
  }
}

.customer-overview {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // border: black 1px solid;
  padding: 1rem;
}
