@import '../styles/base.scss';

.sidebar {
  &__logo {
    height: 4rem !important;
    width: auto !important;
    min-width: 30px !important;
    background: #ffffff;

    &__wrapper {
      margin: 2rem !important;
      display: flex !important;
      justify-content: center !important;
    }
  }
  &__list-item {
    color: #ffffff !important;
    border-radius: 0.5rem !important;
    margin-bottom: 0.5rem !important;
    &__icon {
      color: #ffffff !important;
    }

    &:hover {
      color: #ffffff !important;
      background-color: #03bfff !important;
    }

    &--active {
      font-weight: bold;
      color: #ffffff !important;
      background-color: #03bfff !important;
      fill: #03bfff !important;

      &:hover {
        color: #ffffff !important;
        background-color: #03bfff !important;
      }
    }

    &__wrapper {
      height: 100% !important;
      background-color: #ffffff !important;
    }
  }
}
.sidebar-container {
  height: 100%;
  min-height: 100vh;
  background-color: #232e86;
  padding-top: 6rem !important;
  padding: 1rem 1.5rem;

  footer{ 
    position: absolute;
    bottom: 2rem;

    .header {
      font-family: 'UrbanistSemiBold';
      font-size: 0.8rem;
      color: white;
      opacity: 0.5;
    }
    .subheader {
      font-family: 'UrbanistRegular';
      font-size: 0.8rem;
      color: white;
      opacity: 0.5;
    }
  }
}
