@import '../../styles/styles.scss';

.modal_container {
  padding: 2rem;
  max-width: 30rem;
  &_header {
    font-size: 1.2rem;
    color: $dangerColor;
    font-family: 'UrbanistSemiBold';
  }

  &_text {
    font-size: 0.8rem;
    font-family: 'UrbanistSemiBold';
    color: $spintlyBlack;
    margin: 2rem 0rem;
  }

  &_button_container {
    display: flex;
    align-items: center;
    float: right;
    align-self: flex-end;
    width: 20rem;
    margin-bottom: 2rem;
  }

}