.dropdown-icon{
    position: absolute;
    top: 0;
    right: 0;
    height: 18px;
    width: 18px;
    border-radius: 9px;
    border: 2px solid grey;
    color: gray;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 2rem;
    /* margin-top: 0.8rem; */
}