// todo compare with original file

$spintlyBlack: #1b1b1b;
$themePurple: #353f94;
$bluishGray: #8c95aa21;
$bluishGrayTransparent: #8c95aa00;
$shadowColor: #2929290d;
$borderColor: rgba(0, 0, 0, 0.116);
$alertColor: rgb(231, 66, 66);
$dangerColor: #ff535a;
$cardBorder: #376eff;
$lightGray: rgba(211, 211, 211, 0.219);
$backgroundGray: rgba(211, 211, 211, 0.603);
$darkGray: rgb(100, 100, 100);
$darkBlue-text: #29348e;
$themePurpleLight: #b8bdec;

$mainAccent: #03bfff;
$mainAccentAlternate: #068cb9;
$textRed: #ff535a;
$textgreen: #00d328;
$textDark: #14202a;
