.container {
  width: 36rem;
  height: auto;
  padding: 2.5rem 3rem;
  // max-height: 50rem;
  max-height: 80%;
  // max-height: 80vh;
}

.header {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.dropDown {
  width: 10rem;
}

.mesh_details {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.details_title {
  text-align: left;
  font-size: 1rem;
  letter-spacing: 0px;
  color: #5260e2;
  font-family: 'UrbanistRegular';
}

.details_subtitle {
  text-align: left;
  font-size: 1rem;
  letter-spacing: 0px;
  color: #14202a;
  font-family: 'UrbanistRegular';
}

.button {
  cursor: pointer;
}

.mrb-2 {
  margin-bottom: 2rem;
}

.small-button {
  width: 8rem;
}
