@import '../../components/styles/base/colors';

.display-inline {
  display: inline-block;
}

.container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.icon {
  color: #cf480d;

  &.feed {
    transform: scale(1.8) rotate(270deg);
  }
}

.profile {
  margin-right: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  .pic {
    cursor: pointer;
    width: 3rem;
    height: 3rem;
    margin-right: 0.5rem;
    background-color: #353f94;
  }
  .divider {
    display: inline-block;
    height: auto;
    width: 5px;
  }
}

.header-logo {
  padding-left: 1rem;
}

.popover-root {
  border-radius: 22px !important;
  overflow: hidden !important;
  background: #ffffff;
  border: 1px solid $themePurple;
  border-radius: 10px;
  opacity: 1;
  margin-left: -20px;
  margin-top: 1.5rem !important;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  &::-webkit-scrollbar {
    width: 20px;
    display: none;
  }
  max-height: 45rem !important;
  overflow-y: scroll !important;
}
.sub-header {
  color: $darkGray;
  font-family: 'UrbanistSemiBold';
}
.list-item {
  padding: 0.5rem;
  border-radius: 14px !important;
}
.list-item:hover {
  background-color: #ddd;
  cursor: pointer;
}

.popover {
  width: 250px !important;
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0.5rem;
}

.popover-content {
  &-pic {
    padding: 1rem;
    cursor: pointer;
  }

  .pic {
    background-color: #353f94;
  }

  &-subtitle {
    margin-left: 0.5rem !important;
  }

  &-partner {
    width: 100%;
  }
  &-button {
    width: 100%;
    padding: 0.5rem;
  }
}

.grey-icon {
  color: #8f8c8c !important;
}

.icon {
  position: relative;
  margin-top: 0rem !important;
  margin-left: 1rem;

  :hover {
    cursor: pointer;
  }
  .svg {
    height: 10px;
    width: 10px;
    fill: #a9b0bf;
    transition: all 0.8s;
  }
}
