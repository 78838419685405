// todo compare with original file

$spintlyBlack: #1b1b1b;
$themePurple: #353f94;
$bluishGray: #8c95aa21;
$bluishGrayTransparent: #8c95aa00;
$shadowColor: #2929290d;
$borderColor: rgba(0, 0, 0, 0.116);
$alertColor: rgb(231, 66, 66);
$dangerColor: #ff535a;
$cardBorder: #376eff;
$lightGray: rgba(211, 211, 211, 0.219);
$backgroundGray: rgba(211, 211, 211, 0.603);
$darkGray: rgb(100, 100, 100);
$darkBlue-text: #29348E;

$mainAccent: #03bfff;
$mainAccentAlternate: #068cb9;
$textRed: #ff535a;
$textgreen: #00d328;
$textDark: #14202a;


// refactor
$primary-light: #f18656;
$primary-main: #f46526;
$primary-dark: #cf480d;

$secondary-light: #737373;
$secondary-main: #363535;
$secondary-dark: #141414;

$error-light: #e57373;
$error-main: #f44336;
$error-dark: #d32f2f;

$warning-light: #ffb74d;
$warning-main: #ff9800;
$warning-dark: #f57c00;

$info-light: #64b5f6;
$info-main: #2196f3;
$info-dark: #1976d2;
