@import '../../../../../../styles/variables.scss';
@import '../../../../../../styles/common.scss';

.wrapper {
  width: 46%;
  height: 154px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f4f5f9;
  box-shadow: 0px 0px 50px #6575ff12;
  border: 1px solid #f4f5f9;
  border-radius: 20px;
  margin: 0.5rem;
  @media only screen and (max-width: 1024px) {
    width: 100%;
  }
}
.container {
  display: flex;
  flex: 1;
  .icon__container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0.4;
    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 113px;
      height: 108px;
      background: #fafafc;
      border-radius: 20px;
    }
  }
  .text__container {
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    flex: 0.6;
    .text {
      font-family: 'UrbanistBold';
      color: #353f94;
      font-size: 16px;
    }
    .number {
      font-family: 'UrbanistBold';
      color: #353f94;
      font-size: 40px;
    }
  }
}