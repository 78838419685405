.header-container {
  margin: 2rem 0;
}

.table-container {
  width: 100%;
}

.add-controller {
  .step {
    .stepHeader {
      display: flex;
      align-items: center;

      .counter {
        width: 1.4rem;
        height: 1.4rem;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #5260e2;
        color: #fafafa;
        margin-right: 0.6rem;
      }

      p {
        color: #5260e2;
        font-weight: 500;
      }
    }

    &.step1 {
      .MuiGrid-item {
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }
}

.step2 {
  max-height: 600px;
  overflow-x: hidden;
  overflow-y: auto;
}

.channel {
  .well {
    margin: 1rem 0;
    padding: 0.5rem 0.8rem;
    background-color: #f8f9fa;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #ff535a;

    .title {
      color: #7389c2;
      font-weight: 500;
    }
  }
}

.stepper {
  &.MuiStepper-root {
    padding: 0 1rem 2rem 1rem;
  }

  .MuiStepIcon-root.MuiStepIcon-active,
  .MuiStepIcon-root.MuiStepIcon-completed {
    color: #5260e2;
  }
}

.danger {
  color: #ff535a;
}

.relay {
  .input_field_wrapper {
    margin: 0;
  }
}
