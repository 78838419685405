@import 'base/colors';
@import 'base/fonts';

@mixin center_flex {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ml-2 {
  margin-left: 2rem;
}
.mt-2 {
  margin-top: 2rem !important;
}
.mrb-1 {
  margin-bottom: 1rem !important;
}
.mrb-2 {
  margin-bottom: 2rem !important;
}
.pd-1 {
  padding: 1rem;
}
.pd-2 {
  padding: 2rem !important;
}
.top-3 {
  margin-top: 2rem !important;
}
.header-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.header {
  font-family: 'UrbanistSemiBold';
  letter-spacing: 0px;
  color: #1b1b1b;
  font-size: 1.5rem;
}
.button-cont {
  width: 8rem !important;
}
.button-row {
  width: 6rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}
.status-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.status-text {
  text-align: left;
  font-size: 1rem;
  font-family: 'UrbanistRegular';
}
.text-green {
  color: $textgreen;
}
.text-red {
  color: $alertColor;
}
.text-black {
  color: $textDark;
}
.text-dark-blue {
  color: $darkBlue-text;
}
.box {
  width: 100%;
  min-height: 30rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #7389c2;
  border-radius: 16px;
}
.box-grey {
  width: 100%;
  border: 1px solid #8b94ac;
  border-radius: 24px;
  padding: 1.5rem 2rem;
  margin-bottom: 1rem;
}
.modal-title {
  color: $themePurple;
  font-family: 'UrbanistSemiBold';
  font-size: 1.5rem;
}
.sub-title {
  color: #14202a;
  font-family: 'UrbanistSemiBold';
  font-size: 1.25rem;
}
.mesh-container {
  display: flex;
  border-top: 1px solid #b5b5b5;
  padding: 2rem 0;
}
.right-button-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.mr-0 {
  margin: 0;
}
