// todo compare with original file

$font-path: '../../assets/fonts/';

@font-face {
  font-family: 'UrbanistBlack';
  src: url($font-path + 'Urbanist-Black.ttf') format('truetype');
  font-weight: 900;
}

@font-face {
  font-family: 'UrbanistBold';
  src: url($font-path + 'Urbanist-Bold.ttf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'UrbanistExtraBold';
  src: url($font-path + 'Urbanist-ExtraBold.ttf') format('truetype');
  font-weight: 800;
}

@font-face {
  font-family: 'UrbanistExtraLight';
  src: url($font-path + 'Urbanist-ExtraLight.ttf') format('truetype');
  font-weight: 250;
}

@font-face {
  font-family: 'UrbanistLight';
  src: url($font-path + 'Urbanist-Light.ttf') format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'UrbanistMedium';
  src: url($font-path + 'Urbanist-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'UrbanistRegular';
  src: url($font-path + 'Urbanist-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'UrbanistSemiBold';
  src: url($font-path + 'Urbanist-SemiBold.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'UrbanistThin';
  src: url($font-path + 'Urbanist-Thin.ttf') format('truetype');
  font-weight: 100;
}
