@import '../../styles/common.scss';
@import '../../styles/base.scss';

.button-container {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 0.5rem 0.5rem !important;
  border-radius: 10px !important;
  background-color: #ffff;
  border: solid 2px $edit !important;
}
