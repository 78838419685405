.container {
  width: 100%;
  height: 100vh;
  height: auto;
  display: flex;
  flex-direction: column;
}

.header {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0;
}

.table_container {
  width: 100%;
}

.border_box {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1rem 1rem;
  border: 1px solid rgb(44, 40, 40);
  border-radius: 20px;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.heading {
  color: #14202a;
  font-family: 'UrbanistSemiBold';
  font-size: 1rem;
  margin: 0;
}

.mr_1 {
  margin-right: 2rem;
}
