@import 'base/colors';
@import 'base/fonts';

.cell-link {
  color: $mainAccent !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.user_details {
  display: flex;
  flex-direction: 'row';
  align-items: center;
  
  .avatar {
    background-color: $themePurple;
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1rem;
    p {
      color: white;
      font-size: 0.8rem;
      font-family: 'UrbanistSemiBold';
    }
  }
}

.status {
  &-success {
    color: $secondary-dark;
  }

  &-warning {
    color: $warning-dark;
  }

  &-error {
    color: $error-dark;
  }
}