
@import '../../styles/styles.scss';

.link {
  width: max-content;
  height: 2.2rem;
  color: $mainAccent;
  transition: all ease-in-out 0.2s;
  font-family: 'UrbanistSemiBold';
  font-size: 0.8rem;
  outline: none;
  background: none;
  border: none;
  cursor: pointer;
  &:hover {
    color: $mainAccentAlternate;
  }
}

.disabled {
  color: $bluishGray;
}
