@import '../../styles/common.scss';

.react-tel-input {
  align-self: center;
//   width: 8% !important;
//   position: absolute;
  margin-left: 0 !important;
}

.selected-flag {
  opacity: 1;
  padding-left: 0 !important;
  margin-right: 0 !important;
}

.react-tel-input .form-control {
  position: relative;
  letter-spacing: 0.01rem;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 30px !important;
  margin-left: 0;
  background: transparent;
  border: 0px solid #cacaca !important;
  border-radius: 5px;
  line-height: 25px;
  height: 35px;
  width: 280px;
  outline: none;
  color: #000;
  font-size: 1rem;
  font-family: Lato-Bold;
  border: 0 !important;
  width: 60px !important;
  font-size: 0.7rem;
  font-family: 'UrbanistSemiBold';
  pointer-events: none;
}

.country-list {
  margin-top: 0.5rem !important;
  margin-left: -1rem !important;

  .search-emoji {
    display: none;
  }
  input {
    background: $bluishGray 0% 0% no-repeat padding-box;
    border: 1px solid $borderColor;
    border-radius: 8px;
  }
  li {
    font-size: 0.8rem;
  }
}

.flag-dropdown {
  border-width: 0px !important;
  outline: none !important;
  background: transparent !important;
  left: 0;
}

.selected-flag {
  background-color: transparent !important;
  .flag {
    transform: translateY(-50%);
    margin-top: 0 !important;
  }
}

.flag_input {
  position: relative;
  height: 2.8rem !important;
  background: $bluishGray 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 30px $shadowColor;
  border: 1px solid $borderColor;
  border-radius: 8px;
  margin-top: 2rem;
  width: 190px;
  z-index: 99;
  button {
    position: relative;
    width: 100%;
    height: 2.8rem !important;
    border: 0px solid $borderColor;
    overflow: hidden;
  }
  span {
    font-size: 0.8rem;
    font-family: 'UrbanistSemiBold';
  }

  &.full-width {
    width: 100%;
  }
}
