@import '../../styles/common.scss';

.wrapper {
  text-align: left;
  min-height: 30rem;
  max-height: 72vh;
  overflow: hidden;
  box-shadow: 0px 20px 30px #6575ff12;
  border-top: 1px solid $cardBorder;
  border-left: 1px solid $cardBorder;
  border-right: 1px solid $cardBorder;
  border-top-left-radius: 1.25rem;
  border-top-right-radius: 1.25rem;
  min-width: 100%;
  width: 100%;
  z-index: 1;

  .table-container {
    text-align: left;
    min-height: 30rem;
    max-height: 72vh;
    overflow: auto;
    font-size: 0.875rem;
    min-width: 100%;
    width: 100%;
    background-color: #ffff;
    position: relative;
    // padding-bottom: 8rem;

    &::-webkit-scrollbar {
      display: block;
      width: 0.4rem;
      height: 0.5rem;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: black;
      border-radius: 1rem;
    }

    &::-webkit-scrollbar-button {
      width: 0.5rem; //for horizontal scrollbar
      height: 0.5rem; //for vertical scrollbar
    }

    .t_table {
      min-width: 100%;
      width: 100%;
      //   font-size: $textSize;

      .t_head {
        background-color: #5260e2;
        width: 100%;
        white-space: nowrap;
        position: sticky;
        top: 0;
        z-index: 2;

        .t_h {
          position: sticky;
          top: 0;
          z-index: 3;
          color: #f4f4f4;
          vertical-align: middle;
          text-align: inherit;
          border-bottom: 1px solid #e0e0e0;
          font-family: 'UrbanistSemiBold';
          padding: 2rem 0.75rem;

          &:first-child {
            padding-left: 2.7rem;
          }

          &:last-child {
            padding-right: 2.7rem;

            &.action {
              text-align: center;
            }
          }
        }

        .head-span {
          .span {
            text-align: center;
          }
        }
      }

      .t_body {
        min-height: 100%;
        overflow: auto;

        .t_r {
          // box-shadow: 0 1px 0px 0px #e0e0e0;
          border-bottom: 1px solid rgba(90, 90, 90, 0.2);

          &:last-child {
            // border: none;
          }

          &.rowOnHover {
            cursor: pointer;
            // transition: $transition1;
            transition-duration: 0.1s;

            &:hover {
              // background-color: $light_grey;
            }
          }
        }
      }
    }

    td {
      white-space: nowrap;
      padding: 0.75rem 0.75rem;
      text-align: inherit;
      // z-index: 1;
      // font-size: $table_data_fontsize;
      line-height: 1.35rem;
      font-weight: 400;
      // color: #353f94 !important;
      min-width: 6rem !important;
      max-width: 18rem;
      font-family: 'UrbanistSemiBold';
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &.action {
        padding: 0 0.45rem 0 0;
      }

      &:first-child {
        padding-left: 2.7rem;
      }

      &:last-child {
        padding-right: 2.7rem;

        &.action {
          text-align: center;
        }
      }

      &.center {
        text-align: center;
      }
    }

    td {
      a:not(.icon) {
        color: #00e;
        color: -webkit-link;
      }
    }

    .TableSearch {
      padding: 0.65rem 0.75rem;

      >* {
        max-width: 14rem;
      }

      .input-container {
        width: 6rem !important;
      }

      input {
        width: 100%;
        background: $bluishGray 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 30px $shadowColor;
        border: 1.2px solid $borderColor;
        border-radius: 8px;
        padding: 0.5rem 1rem;
        background: $bluishGrayTransparent 0% 0% no-repeat padding-box;
        outline: none;
        font-size: 0.8rem;
        font-family: 'UrbanistSemiBold';
        min-width: 5rem;
      }
    }
  }

}

.TableFooter {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #ffff;
  position: relative;
  border-bottom: 1px solid $cardBorder;
  border-left: 1px solid $cardBorder;
  border-right: 1px solid $cardBorder;
  border-bottom-left-radius: 1.25rem;
  border-bottom-right-radius: 1.25rem;
  font-size: 1rem;
  width: 100%;
  padding: 1.5rem 1rem;
  border-top: 1px solid rgba(90, 90, 90, 0.2);

  >div {}

  .buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    button {
      width: 2rem;
      height: 2rem;

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      font-size: 1rem;
      opacity: 1;

      // color: $menu;
      &.active {
        // color: $accent;
        opacity: 1 !important;
      }

      &:disabled {
        opacity: 0.45;
      }

      svg {
        font-size: 1.4rem;
      }
    }
  }

  .dropdown {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .info {
      margin-right: 1rem;
    }
  }
}

.emptyContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 20rem;
  text-align: center;
  padding: 7%;
  font-size: 0.8rem;
  font-family: 'UrbanistSemiBold';
  user-select: none;
  font-size: inherit;
  width: 100%;
}