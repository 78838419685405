@import '../../../styles/common.scss';

.header-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.header {
  font-family: 'UrbanistSemiBold';
  letter-spacing: 0px;
  color: #1b1b1b;
  font-size: 1.5rem;
}

.button-cont {
  width: 8rem !important;
}


