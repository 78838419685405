@import '../../styles/common.scss';
@import '../../styles/variables.scss';

.avatar {
  background-color: $themePurple;
  border-radius: 50%;
  @include center_flex;

  p {
    color: white;
    font-size: 2.5rem;
    font-family: 'UrbanistSemiBold';
  }
}

.xsmall {
  width: 2rem;
  height: 2rem;
  p {
    font-size: 1rem !important;
  }
}
.small {
  width: 6rem;
  height: 6rem;
}

.medium {
  width: 10rem;
  height: 10rem;
}

.large {
  width: 15rem;
  height: 15rem;
}
