// todo compare with original file

@import '../../styles/common.scss';

.container {
  height: 100vh;
  display: flex;
  flex-direction: row;
  background-color: #fff;

  @media only screen and (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .left_section {
    flex: 0.55;
    display: flex;
    justify-content: center;
    align-items: center;

    @media only screen and (max-width: 1024px) {
      display: none;
    }

    .login_image {
      background-image: url(../../assets/images/landing.jpg);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      width: 100%;
      height: 100%;
    }
  }

  .right_section {
    padding: 4rem 2rem;
    flex: 0.45;
    position: relative;
    .logo_container {
      display: flex;
      flex-direction: row;
      align-items: center;

      img {
        width: 8rem;
      }
      h2 {
        font-size: 2.2rem;
        color: $spintlyBlack;
        font-family: 'UrbanistSemiBold';
        margin: 0.8rem 0.65rem 0rem 0.5rem;
      }
      h1 {
        font-size: 2.2rem;
        color: $spintlyBlack;
        margin-top: 1rem;
        font-family: 'UrbanistSemiBold';
      }
    }

    .secodary_header {
      font-size: 1rem;
      color: $themePurple;
      margin-top: 1rem;
      font-family: 'UrbanistSemiBold';
    }

    .form {
      margin-top: 3.5rem;
      max-width: 25rem;
      h1 {
        font-size: 1rem;
        font-family: 'UrbanistSemiBold';
        color: $spintlyBlack;
        margin-bottom: 1.5rem !important;
      }

      .buttonContainer {
        margin-top: 2rem;
      }
    }
  }
}
