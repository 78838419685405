@import '../styles/styles.scss';

.label {
  transition: all 0.3s ease;
  font-size: 0.8rem;
  font-family: 'UrbanistSemiBold';
  color: $themePurple;
  display: flex;
  flex-direction: row;
  position: absolute;
  margin-bottom: 1rem;
  // width: 10rem;

  &_inactive {
    opacity: 0;
    transform: translateY(0rem);
  }

  &_active {
    transform: translate(-0.5rem, -2rem);
  }

  &_none {
    display: none;
  }

  .placeholder {
    width: max-content;
    margin-right: 2rem;
  }
}


.cont {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: black;
}

.input_field {
  width: 100%;
  height: 2.8rem;
  background: $bluishGray 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 30px $shadowColor;
  border: 1px solid $borderColor;
  border-radius: 8px;
  margin-top: 2rem;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  position: relative;

  p {
    // position: absolute;
    z-index: 0;

  }

  input {
    position: relative;
    background: $bluishGrayTransparent 0% 0% no-repeat padding-box;
    outline: none;
    font-size: 0.8rem;
    font-family: 'UrbanistSemiBold';
    width: 100%;

    &:focus {
      outline: none;
      background: $bluishGrayTransparent 0% 0% no-repeat padding-box;
    }

    &:active {
      outline: none;
      background: $bluishGrayTransparent 0% 0% no-repeat padding-box;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px #f1f2f5 inset !important;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  input[type='password'] {
    font-size: 1.25rem;
    letter-spacing: 0.2rem;
  }

  input[type='password']::placeholder {
    font-size: 0.8rem;
    letter-spacing: 0rem;
  }

  .eye_icon {
    position: absolute;
    right: 1rem;
    margin-top: 0rem !important;

    .svg {
      height: 25px !important;
      width: 25px !important;
      fill: #a9b0bf;
      transition: all 0.8s;
    }
  }

  .icon {
    position: absolute;
    right: 1rem;
    margin-top: 0rem !important;

    :hover {
      cursor: pointer;
    }

    .svg {
      height: 10px;
      width: 10px;
      fill: #a9b0bf;
      transition: all 0.8s;
    }

    .svg-transform {
      height: 10px;
      width: 10px;
      fill: black;
      transform: rotateX(-180deg);
      transition: all 0.5s;
    }
  }

  .description-container {
    position: absolute;
    right: 1rem;
    margin-top: 0rem !important;
    background-color: #29348E;
    padding: 0 0.5rem;
    height: 1rem;
    border-radius: 0.2rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    p {
      color: white;
      font-size: 12px;
      font-family: 'UrbanistSemiBold';
      position: relative;
    }
  }
}

.error {
  color: $dangerColor;
  margin-left: 0.8rem;
  font-size: 0.8rem;
  font-family: 'UrbanistSemiBold';
}

.danger {
  border: 1px solid $alertColor;
}

.focused {
  border: 1px solid $themePurple;
}

.disabled {
  opacity: 0.5;
  border: 1px solid $themePurple;
}

.dropdown {
  position: absolute;
  width: 100%;
  max-height: 350px;
  overflow: auto;
  top: 0rem;
  left: 0;
  list-style: none;
  background-color: #f1f1f1;
  padding: 1rem;
  border-radius: 14px !important;
  z-index: 100;
  background: #ffffff;
  border: 1px solid $themePurple;
  border-radius: 10px;
  opacity: 1;
  margin-top: 3rem !important;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);

  &::-webkit-scrollbar {
    width: 20px;
    display: none;
  }

  li {
    color: $darkGray;
    font-family: 'UrbanistSemiBold';
    padding: 0.5rem;
  }

  li:hover {
    background-color: #ddd;
    cursor: pointer;
    border-radius: 10px;
  }
}

.dropdown-hide {
  display: none;
  position: absolute;
  width: 100%;
  max-height: 350px;
  overflow: scroll;
  overflow: hidden;
  top: 2.7rem;
  left: 0;
  list-style: none;
  background-color: #f1f1f1;
  padding: 1rem;
  border-radius: 14px !important;
  z-index: 1;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);

  li {
    color: $darkGray;
    font-family: 'UrbanistSemiBold';
    padding: 0.5rem;
  }

  li:hover {
    background-color: #ddd;
    cursor: pointer;
  }
}

.phone-input-wrapper {
  position: relative;
  margin-right: 0 !important;
}

.iso-text {
  font-size: 0.8rem;
  font-family: 'UrbanistSemiBold';
  margin-right: 1rem;
  position: absolute;
  left: 50px;
  display: none;
}