.container {
  width: 100%;
  height: 25rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.sub_container {
  display: flex;
  height: 25rem;
  flex-direction: column;
  flex: 0.333;
  border: 1px solid black;
}

.center_box {
  width: 100%;
  height: 25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 0.4;
  border: 2px solid #8b94ac;
  border-radius: 20px;
  z-index: 5;
  background-color: white;
}

.left_container {
  display: flex;
  height: 25rem;
  flex-direction: column;
  flex: 0.3;
  align-items: flex-end;
  padding: 1rem 0;
  justify-content: space-between;
  margin-right: -5px;
}

.right_container {
  display: flex;
  height: 25rem;
  flex-direction: column;
  flex: 0.3;
  align-items: flex-start;
  padding: 1rem 0;
  justify-content: space-between;
  margin-left: -5px;
}
.right_align {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.border_box {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.4rem 0.5rem;
  padding-bottom: 0.3rem;
  border: 1px solid #8b94ac;
  border-radius: 8px;
  margin: 1rem 0 !important;
  height: 2.5rem;
}
.width_100 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.mesh_name {
  text-align: center;
  font-size: 1rem;
  letter-spacing: 0px;
  color: #8b94ac;
  font-family: 'UrbanistRegular';
}

.mrgt-02 {
}

.mrgb-05 {
  margin-bottom: 0.5rem;
}
.mrgb-1 {
  margin-bottom: 2rem;
}

.mesh_input {
  padding: 0.1rem;
  border-bottom: 1px solid grey;
}
