@import '../../styles/common.scss';

.container {
  display: flex;
  height: 100%;

  padding: 1rem;
  position: relative;
  overflow: hidden;
  flex-grow: 1;
  display: flex;
  justify-content: space-around;

  // gap:;

  align-items: center;
  border: 1px solid $cardBorder;
  border-radius: 1rem;
  background: white;
  box-shadow: 0px 20px 30px #6575ff12;
  border-radius: 1.2rem;
  @media only screen and (min-width: 1400px) {

    width: 500px;
    // height: 250px;
  }

  &__left_section {
    flex: 0.2;
    @include center_flex;
    flex-direction: column;

    .icon_container {
      @include center_flex;
      background: $lightGray;
      border-radius: 1.5rem;
      width: 4.2rem;
      height: 3.6rem;

      img {
        width: 3rem;
        height: 3rem;
      }
    }

    .left_bottom {
      height: 1.2rem;
    }
  }

  &__right_section {
    padding-left: 1rem;
    flex: 0.8;

    .text_container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 0.2rem 0rem;
      height: 3.6rem;
      h1 {
        font-size: 1.2rem;
        font-family: 'UrbanistSemiBold';
        letter-spacing: 0.26px;
        color: $themePurple;
        line-height: 1rem;
      }

      h2 {
        font-size: 1.8rem;
        font-family: 'UrbanistBold';
        letter-spacing: 0.26px;
        color: $themePurple;
        line-height: 1.8rem;
        margin-bottom: 0.2rem;
      }
    }

    .right_bottom {
      display: flex;
      flex: 1;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      height: 1.2rem;
      margin-top: 0.5rem;

      .text_section {
        flex: 0.6;
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 8rem;

        .month_text {
          font-family: 'UrbanistSemiBold';
          letter-spacing: 0px;
          color: #14202a;
          opacity: 0.5;
          font-size: 0.8rem;
          line-height: 0.8rem;
        }

        .month_total {
          font-size: 1rem;
          font-family: 'UrbanistBold';
          letter-spacing: 0.26px;
          color: $themePurple;
          line-height: 1rem;
        }
      }

      .nav_container {
        cursor: pointer;
        flex: 0.4;
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}
