@import './common.scss';

.wrapper {
  padding: 2rem 2rem;
  max-height: calc(100vh - 20px);
  -ms-overflow-style: none;
  scrollbar-width: none;

  h1 {
    color: $themePurple;
    font-family: 'UrbanistSemiBold';
    font-size: 1rem;
    margin-bottom: 1.8rem !important;
  }
  h3 {
    color: $themePurple;
    font-family: 'UrbanistSemiBold';
    font-size: 1rem;
  }
  .hr-one {
    margin-top: 1.6rem;
    margin-bottom: 0.8rem;
  }
  .hr-two {
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
  }
}

.wrapper::-webkit-scrollbar {
  display: none;
}

.modal-input {
  width: 400px;
}

.dropDown__inputs__container {
  display: flex;
  justify-content: space-between;
  flex: 1;
  width: 100%;

  div:nth-child(1) {
    margin-right: 0.5rem;
  }

  div:nth-child(2) {
    margin-left: 0.5rem;
  }

  &__dropDown {
    border: #353f94;
    background-color: #353f94;
  }

  .row {
    flex: 1;
  }
}

.dropDown__inputs__container__dropDown {
  background-color: #353f94;
  width: 30%;
}

.modules__container {
  background-color: #f9fafb;
  padding: 1rem;
  margin-top: 0.2rem;
  border-radius: 14px;
}

.modules__header {
  display: flex;
  justify-content: space-between;

  p {
    font-family: 'UrbanistSemiBold';
    color: $darkGray;
  }

  p:first-child {
    color: $themePurple;
  }

  svg {
    height: 10px;
    width: 10px;
  }
}

.navigation__part {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;

  p {
    margin-left: 0.5rem;
  }
}

.modules__content {
  width: 80%;
  display: flex;
  justify-content: space-between;

  label {
    font-size: 12px;
    font-family: 'UrbanistSemiBold';
    margin-left: 0.4rem;
    color: $darkGray;
  }
}

.button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
  width: 16rem;
  float: right;

  button:last-child {
    margin-right: 0;
  }
}

.multi-field-max-length-text {
  color: $mainAccent;
  margin-top: 40px;
}

.multi-field {
  .multi-field-add-button {
    background-color: $mainAccent;
    height: 2rem;
    padding: 0.3rem 1.7rem;
    text-transform: none;
    margin-left: 20px;
    border-radius: 5px;
    border: none;
    transition: all ease-in-out 0.2s;
    color: white;

    &:hover {
      background-color: $mainAccent;
      -webkit-box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.09);
      -moz-box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.09);
      box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.09);
    }
  }

  .multi-field-title-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 0 0;

    .multi-field-title {
      h3 {
        font-size: 1rem;
        line-height: 1.2rem;
        letter-spacing: 0px;
        color: $themePurple;
      }

      p {
        font-size: 0.813rem;
        line-height: 0.938rem;
        letter-spacing: 0px;
        color: $mainAccent;
      }
    }
  }

  .multi-field-item {
    display: flex;
    align-items: flex-start;
    .multi-field-item-input-wrapper {
      flex: 1;
      display: block;
    }

    .multi-field-remove-button {
      height: 2.8rem;
      margin-top: 2rem;
      color: #8c95aa;

      &:hover {
        background-color: transparent !important;
        color: $spintlyBlack;
      }
    }

    .multi-field-add-button {
      margin-top: 2.2rem;
      height: 2.4rem;
      padding: 0.3rem 1rem;
    }
  }
}
