@import '../../styles/styles.scss';

.modal_container {
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  width: 100vw;
  background-color: $backgroundGray;
  display: flex;
  align-items: center;
  justify-content: center;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }


  .modal_body {
    width: auto;
    min-width: 20rem;
    // max-width: 30rem;
    height: auto;
    min-height: 10rem;
    max-height: min-content;
    border-radius: 2rem;
    background: white;
    position: relative;
    z-index: 90;
    border-width: 1px;
    padding-bottom: 2rem;
  }

  .default {
    border-color: $themePurple;
  }

  .danger {
    border-color: $dangerColor;
  }
}

.width_30 {
  width: 30rem;
}
