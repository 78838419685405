@import '../../styles/common.scss';

footer{ 
  position: absolute;
  bottom: 2rem;
  width: 90%;
  .header {
    font-family: 'UrbanistSemiBold';
    font-size: 0.8rem;
    color: $spintlyBlack;
    opacity: 0.5;
  }
  .bottom_section{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-right: 1rem;
    flex: 1;
    .subheader {
      font-family: 'UrbanistRegular';
      font-size: 0.8rem;
      color: $spintlyBlack;
      opacity: 0.5;
      flex: 0.5;
    }
    .links {
      flex: 0.5;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      p {
        width: max-content;
        height: 1.2rem;
        color: rgb(53, 63, 148);
        font-family: 'UrbanistMedium';
        transition: all ease-in-out 0.2s;
        font-size: 0.8rem;
        outline: none;
        background: none;
        border: none;
        cursor: pointer;
      }
    }
  }
  
}