@import '../../../styles/common.scss';

.header {
  font-family: 'UrbanistBold';
  font-size: 1.4rem;
  color: $spintlyBlack;
  margin-left: 1rem;
  margin-bottom: -2rem;
  margin-top: 2rem;
}

.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 90%;
  // height: 90%;

  margin-top: 2rem;
  flex: 1;
 
  .div{
    // margin-top: 10rem;
    @media only screen and (max-width: 1024px)  {
      margin-top: 0;
    }
  }
 
  .grid_cards {
    // margin-top: 10rem;
    flex-grow: 0;
    max-width: 100%;
    flex-basis: 100%;
    padding: 1rem;
    @media only screen and (max-width: 1024px)  {
      max-width: 100%;
      flex-basis: 100%;
    }
  }
}