@import '../../../../../styles/common.scss';
@import '../../../../../styles/variables.scss';

.container {
  display: flex;
  flex-direction: row;
  flex: 1;
  margin-top: 2rem;

  @media only screen and (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-direction: column-reverse;
  }

  .left_section {
    display: flex;
    flex: 0.65;
    padding: 0.5rem;
    @media only screen and (max-width: 1024px) {
      flex: 1;
    }


    .left_inner {
      display: flex;
      flex-direction: row;
      height: 100%;
      width: 100%;
      padding: 3rem 2rem !important;
      background: white;
      box-shadow: 0px 20px 30px #6575ff12;
      border-radius: 1.5rem;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }
  
  .right_section {
    width: 100%;
    display: flex;
    flex: 0.35;
    padding: 0.5rem;
    @media only screen and (max-width: 1024px) {
      flex: 1;
    }
  }

  .customer-overview {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // border: black 1px solid;
    // padding: 1rem;
  }
  
  .stat-container {
    display: flex;
  }
  
  .icon {
    &-red {
      color: $red-900 !important;
    }
    &-green {
      color: $green-900 !important;
    }
    &-blue {
      color: $blue-900 !important;
    }
    &-yellow {
      color: $yellow-900 !important;
    }
    &-purple {
      color: $purple-900 !important;
    }
    &-orange {
      color: $orange-900 !important;
    }
    &-brown {
      color: $brown-900 !important;
    }
    &-blue-grey {
      color: $blue-grey-900 !important;
    }
  }
}
