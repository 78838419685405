// todo compare with original file

@import '../../styles/common.scss';

.button {
  width: 100%;
  height: 2.2rem;
  background-color: white;
  border: 1px solid $mainAccent;
  border-radius: 5px;
  border: 0px solid;
  align-items: center;
  justify-content: center;
  transition: all ease-in-out 0.2s;
  font-family: 'UrbanistSemiBold';
  font-size: 0.8rem;
  color: $mainAccent;
  margin-right: 1rem;
  &:hover {
    -webkit-box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.09);
    -moz-box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.09);
    box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.09);
    z-index: 10;
    filter: brightness(0.55);
    cursor: pointer;
  }
}

.button-small {
  width: 25%;
  height: 2.2rem;
  background-color: white;
  border: 1px solid $mainAccent;
  border-radius: 5px;
  border: 0px solid;
  align-items: center;
  justify-content: center;
  transition: all ease-in-out 0.2s;
  font-family: 'UrbanistSemiBold';
  font-size: 0.8rem;
  color: $mainAccent;
  margin-right: 1rem;
  &:hover {
    -webkit-box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.09);
    -moz-box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.09);
    box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.09);
    z-index: 10;
    filter: brightness(0.55);
    cursor: pointer;
  }
}

.button-smaller {
  width: 15%;
  height: 2.2rem;
  background-color: white;
  border: 1px solid $mainAccent;
  border-radius: 5px;
  border: 0px solid;
  align-items: center;
  justify-content: center;
  transition: all ease-in-out 0.2s;
  font-family: 'UrbanistSemiBold';
  font-size: 0.6rem;
  color: $mainAccent;
  margin-right: 1rem;
  &:hover {
    -webkit-box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.09);
    -moz-box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.09);
    box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.09);
    z-index: 10;
    filter: brightness(0.55);
    cursor: pointer;
  }
}

.shadow {
  box-shadow: 0px 5px 5px #0000001a;
}

.primary {
  background-color: white;
  border: 1px solid $mainAccent;
  color: $mainAccent;
}

.outlined {
  background-color: rgba(255, 255, 255, 0);
  border: 1px solid $mainAccent;
  color: $mainAccent;
}

.disabled {
  color: $mainAccent;
  border: 1px solid #8c95aabe;
  box-shadow: null;
}

.secondary {
  background-color: white;
  border: 1px solid $darkGray;
}

.danger {
  background-color: white;
  border: 1px solid $dangerColor;
}
