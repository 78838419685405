.container {
  width: 46rem;
  height: auto;
  max-height: 45rem;
  padding: 2.5rem 3rem;
  overflow-y: scroll;
}

.header {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
}
.details_input {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 2rem;
}

.meshButton-container {
  width: 25rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-top: 1.6rem;
}

.checkbox-width {
  height: 4rem;
  width: 15rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0 !important;
}

.mrg-0 {
  margin: 0 !important;
}

.mrg-r2 {
  margin-right: 2rem;
}
.mrg-b2 {
  margin-bottom: 2rem;
}

.input_container {
  width: 40%;
  margin-right: 2rem;
}

.button_width {
  width: 35%;
  margin-right: 0.5rem;
}

.button-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.error {
  color: #ff535a;;
  margin-left: 1rem;
}