@import '../../../styles/common.scss';

.header-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.header {
  font-family: 'UrbanistSemiBold';
  letter-spacing: 0px;
  color: #1b1b1b;
  font-size: 1.5rem;
}

.button-cont {
  width: 8rem !important;
}

.wrapper {
  padding: 2rem 2rem;
  max-height: calc(100vh - 20px);
  -ms-overflow-style: none;
  scrollbar-width: none;

  .spacing {
    height: 50px;
    width: 100%;
  }

  .divider {
    margin-top: 20px;
    height: 1px;
    width: 100%;
    background-color: lightgray;
  }

  .small-heading {
    font-family: 'UrbanistSemiBold';
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.2rem;
    letter-spacing: 0;
    color: #14202a;
  }

  .hr-two {
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
  }
}

.container {
  display: flex;
  flex-direction: row;
  flex: 1;
  margin-top: 2rem;

  @media only screen and (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-direction: column-reverse;
  }

  .left_section {
    flex: 0.5;
    padding: 0.5rem;
    max-height: 700px;
    width: '100%';
    overflow-x: hidden;
    overflow-y: auto;

    @media only screen and (max-width: 1024px) {
      flex: 1;
    }
  }

  .right_section {
    flex: 0.5;
    padding: 0.5rem;
    @media only screen and (max-width: 1024px) {
      flex: 1;
    }
  }
}

.button-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin: 0 0 2rem;
}

.overflowControl {
  padding-right: 10px;
  max-height: 200px;
  width: '100%';
  overflow-x: hidden;
  overflow-y: auto;
}
