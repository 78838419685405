@import '../styles/base/colors';

.loader {
  position: absolute !important;
  z-index: 1500;
  width: 100%;
  background-color: $themePurpleLight !important;
}

.primary_color {
  background-color: $themePurple !important;
}
